import React from 'react';

interface Props extends Omit<React.HTMLProps<HTMLImageElement>, 'src' | 'alt' | 'crossOrigin'>{}

const HorizontalPlaneIcon = (props: Props) => {
  return (
    <img {...props} src={require('../../images/plane-horizontal.svg').default} alt="Plane Icon" />
  );
};

export default HorizontalPlaneIcon;
