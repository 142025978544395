import React from 'react';
import HorizontalPlaneIcon from '../Icons/HorizontalPlaneIcon';

const MarqueeDivider = () => {
  return (
    <div className="marquee my-5">
       <span>
           <HorizontalPlaneIcon height={60} />
       </span>
    </div>
  );
};

export default MarqueeDivider;
