import React, { useEffect } from 'react';

const useCanAutoplay = () => {
  const [canAutoplay, setCanAutoplay] = React.useState(false);
  useEffect(()=>{
    if(typeof window !== 'undefined' && window.innerWidth > 991){
      setCanAutoplay(true);
    }
  },[]);

  return canAutoplay;
};

export default useCanAutoplay;
