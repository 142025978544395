import './side-diamond.scss';

import React, { ReactNode } from 'react';
import Diamond from '../Diamonds/Diamond';

interface Props {
  label: ReactNode;
  icon: ReactNode;
}

const SideDiamond = ({ label, icon }: Props) => {
  return (
    <Diamond className="side-diamond" label={label} icon={icon} />
  );
};

export default SideDiamond;
