import React from 'react';
import FlexRow from '../Flex/FlexRow';

interface Props {
  children: React.ReactNode;
  type: 'aviation' | 'technology';
}

const CallToAction = ({ children, type }: Props) => {
  return (
    <FlexRow className="call-to-action" alignCenter childSpacingX={2}>
      <div className="icon-container mr-5">
        <img className="img-fluid" src={require('../../assets/img/call-to-action.svg').default} alt="Call to Action Icons" />
      </div>

      <div className="content-container text-center">
        <div className="text-accent mb-1">We want our clients to be</div>
        <div className="text-accent mb-3"><em>{ type === 'technology' ? 'proactive, knowledgeable and successful' : 'prepared, informed and confident'}!</em></div>

        <div className="content">{children}</div>
      </div>
    </FlexRow>
  );
};

export default CallToAction;
