import React from 'react';
import { Link } from 'gatsby';

import CallToAction from './CallToAction';

interface Props {
  tagline: string;
  type: 'aviation' | 'technology';
}

const SidePageCallToAction = ({ tagline, type }: Props) => {
  return (
    <Link to="/contact">
      <div className="pt-4 mt-5">
        <CallToAction type={type}>
          <em>{tagline}</em>
        </CallToAction>
      </div>
    </Link>
  );
};

export default SidePageCallToAction;
