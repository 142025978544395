import React from 'react';

interface Props extends Omit<React.HTMLProps<HTMLImageElement>, 'src' | 'alt' | 'crossOrigin'>{}

const GlobeIcon = (props: Props) => {
  return (
    <img {...props} src={require('../../images/globe.svg').default} alt="Glove Icon" />
  );
};

export default GlobeIcon;
