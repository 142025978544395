import './side-page-layout.scss';

import React from 'react';
import { Col, Row } from 'reactstrap';
import { FlexColumn, FlexRow, Navbar } from '../index';
import BaseLayout from '../Layouts/BaseLayout';
import useNavigationScroller from '../../hooks/useNavigationScroller';
import { Link } from 'gatsby';
import LargeBar from '../Bars/LargeBar';
import PartnersFooter from '../PartnersFooter/PartnersFooter';
import SidePageCallToAction from '../CallToAction/SidePageCallToAction';
import MarqueeDivider from '../MarqueeDivider/MarqueeDivider';
import useCanAutoplay from '../../hooks/useCanAutoplay';

interface Props {
  title: string | undefined;
  Header: React.ReactNode;
  children: React.ReactNode;
  callToActionTagline?: string;
  includeAirplaneMarquee?: boolean;
  type: 'aviation' | 'technology';
}

const SidePageLayout = ({ title, Header, includeAirplaneMarquee, children, callToActionTagline, type }: Props) => {
  const wrapper = React.useRef<HTMLDivElement>(null);
  useNavigationScroller(wrapper);
  const canAutoplay = useCanAutoplay();

  return (
    <BaseLayout title={title}>
      <Navbar />

      <Row className="flex-fill">
        <Col md={4}></Col>
        <Col className="d-flex flex-column p-3 flex-fill" md={8}>
          <div className="pt-2 px-2">
            <Link to="/">
              <img className="img-fluid hero-opacity" src={require('../../images/logo-horizontal.svg').default} alt="Aviation Safety Solutions" style={{ height: '20vh' }} />
            </Link>
          </div>
        </Col>
      </Row>

      <div className="wrapper" ref={wrapper}>
        <div className="overflow-hidden position-absolute" style={{ height: '100vh', top: 0, left: 0, right: 0, bottom: 0, zIndex: -1 }}>
          <div className="bg-primary position-relative overflow-hidden" style={{ height: '100vh' }}>

            <FlexColumn className="hero-content">
              <Row className="flex-fill">
                <Col md={4}></Col>
                <Col className="d-flex flex-column p-3 flex-fill" md={8} style={{ marginTop: 180 }}>
                  {Header}
                </Col>
              </Row>
            </FlexColumn>

            <video className="hero-video" autoPlay={canAutoplay} loop muted>
              <source
                src="/assets/video/section-cloud.mp4"
                type="video/mp4"
              />
            </video>
          </div>
        </div>

        {children}

        {callToActionTagline?.trim() && (
          <Row>
            <Col className="d-none d-lg-block" md={4}/>
            <Col md={7}>
              <SidePageCallToAction tagline={callToActionTagline} type={type} />
            </Col>
          </Row>
        )}

        {includeAirplaneMarquee && (
          <MarqueeDivider />
        )}

        <div className="hero-opacity my-1 mt-5 text-center">
          <LargeBar style={{ width: '60%' }} />
        </div>

        <section className="">
          <PartnersFooter />
        </section>

        <div className="hero-opacity mt-3 pb-5 text-center text-secondary">
          <FlexRow justifyCenter childSpacingX={1}>
            <i className="mdi mdi-36px mdi-star" />
            <i className="mdi mdi-36px mdi-star" />
            <i className="mdi mdi-36px mdi-star" />
            <i className="mdi mdi-36px mdi-star" />
            <i className="mdi mdi-36px mdi-star" />
          </FlexRow>
        </div>
      </div>
    </BaseLayout>
  );
};

export default SidePageLayout;
