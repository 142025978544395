import './diamond.scss';

import React, { ReactNode } from 'react';
import classnames from 'classnames';
import { Link } from 'gatsby';

interface Props {
  label: ReactNode;
  className?: string;
  linkTo?: string;
  icon?: ReactNode;
  tag?: React.ElementType;
  backgroundImage: string;
}

const Diamond = ({ label, className, backgroundImage, icon, linkTo, tag: Tag = 'div' }: Props) => {
  const WrapperTag = linkTo ? Link : 'div';

  return (
    <Tag className={classnames('diamond', className)} style={{ 'background-image': `url(${backgroundImage})` }}>
      <WrapperTag to={linkTo || undefined!}>
        <div className="text-center">
          <h3 className="mb-1">{label}</h3>
          <div className="hero-opacity">{icon}</div>
        </div>
      </WrapperTag>
    </Tag>
  );
};

export default Diamond;
